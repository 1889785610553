
import {Component, Vue} from 'vue-property-decorator';
import moment from 'moment';
import {
  orders,
  users,
  shops,
  afterSaleOrders,
  riderSignupRequests,
  channels,
  applications,
} from '../../../resources';
import {ObjectId} from 'bson';
import * as echarts from 'echarts';
import lodash from 'lodash';

interface SummaryData {
  todayNum: number;
  lastNum?: number;
  rate?: number | string;
  totalNum: number;
}
@Component({
  name: 'mallOverviewIndex',
})
export default class extends Vue {
  private deliveryShow = false; //是否显示骑手待审核
  private time = '6';
  private updateTime = ''; //更新时间
  private transactionAmount: null | SummaryData = null; //交易金额
  private transactionNum: null | SummaryData = null; //交易订单数
  private userNum: null | SummaryData = null; //用户数
  private businessShop: null | SummaryData = null; //营业中的店铺数
  private pendingPaymentNum = 0; //待付款订单
  private deliveredNum = 0; //待发货
  private pendingRefund = 0; //待处理退款单
  private withdrawNum = 0; //提现待审核
  private versionReleased = 0; //版本待发布
  private riderReviewed = 0; //骑手待审核
  private shopSalesRanking: Array<{
    _id: {shopId: ObjectId};
    amount: number;
  }> = []; //店铺排行榜
  private searchData = {
    time: [
      moment({hour: 0})
        .subtract({day: Number(6)})
        .toDate(),
      moment({hour: 0}).toDate(),
    ],
  };
  private applicationId = ObjectId.createFromHexString(
    this.$route.params.applicationId,
  );
  private xData: Array<string> = [];
  private yUser: Array<number> = [];
  private yCount: Array<number> = [];
  async created() {
    this.updateTime = moment().format('YYYY-MM-DD HH:mm:ss');
    this.checkSummary(); //查询今日交易金额 交易订单数 新增用户数
    this.checkBusinessShop(); //查询营业中的店铺数
    this.checkNum(); //待处理退款单 提现待审核 版本待发布 骑手待审核
    this.checkCharts(); //查询图表
    this.checkSalesRanking(); //店铺销售额排名
    this.$store.state.applicationId = this.$route.params.applicationId;

    const application = (
      await applications.find(stage =>
        stage.$match(match => {
          match(
            f => f('_id'),
            e => e.$eq(this.applicationId),
          );
          return match;
        }),
      )
    ).find(() => true);
    if (application?.spec.enableServices) {
      application?.spec.enableServices.forEach(item => {
        if (item === '配送') {
          this.deliveryShow = true;
        }
      });
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private shopInfoData: any = {};
  mounted() {
    const echartbox = document.getElementById('echartbox') as HTMLCanvasElement;
    window.onresize = () => {
      return (() => {
        echarts.init(echartbox).resize();
      })();
    };
  }
  //获取店铺名称
  private getShopName(id: ObjectId) {
    let name = '--';
    if (id) {
      name = this.shopInfoData[id.toHexString()].spec.name;
    }
    return name;
  }
  //查询图表
  private async checkCharts() {
    const list = await orders.find(stage =>
      stage
        .$match(match =>
          match.$and(and => {
            and(query => {
              query(
                f => f('spec')('applicationId'),
                e =>
                  e.$eq(
                    ObjectId.createFromHexString(
                      this.$route.params.applicationId,
                    ),
                  ),
              )(
                f => f('status')('payInfo'),
                e => e.$exists(true),
              );
              return query;
            });
            if (this.searchData.time && this.searchData.time.length > 0) {
              and(query =>
                query(
                  f => f('metadata')('creationTimestamp'),
                  e => e.$gte(moment(this.searchData.time[0]).toDate()),
                ),
              );
              and(query =>
                query(
                  f => f('metadata')('creationTimestamp'),
                  e =>
                    e.$lte(
                      moment(this.searchData.time[1])
                        .add({day: 1})
                        .subtract({millisecond: 1})
                        .toDate(),
                    ),
                ),
              );
            }
            return and;
          }),
        )
        .$facet(facet =>
          facet('chartsData', chartsStage =>
            chartsStage
              .$group(
                e =>
                  e.$object(object =>
                    object('date', e =>
                      e.$dateToString(
                        date =>
                          date.$fieldPath(f =>
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            f('metadata')('creationTimestamp'),
                          ),
                        format => format.$literal('%Y-%m-%d'),
                        timezone => timezone.$literal('+08'),
                      ),
                    ),
                  ),
                group =>
                  group('user', e =>
                    e.$sum(sum =>
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      sum.$fieldPath(f => f('spec')('amount')('user')),
                    ),
                  )('count', e => e.$sum(sum => sum.$literal(1))),
              )
              .$sort(sort => sort(f => f('_id')('date'), '升序')),
          ),
        ),
    );
    this.xData = list[0].chartsData.map(v => v._id.date as string);
    this.yUser = list[0].chartsData.map(v => v.user / 100);
    this.yCount = list[0].chartsData.map(v => v.count);
    const chart = document.getElementById('echartbox') as HTMLCanvasElement;
    const myChart = echarts.init(chart);
    // 绘制图表
    myChart.setOption({
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'line',
        },
      },
      grid: {
        left: '5%',
        right: '5%',
        bottom: '3%',
        containLabel: true,
      },
      xAxis: {
        data: this.xData,
        max: this.xData[this.xData.length - 1],
        axisLabel: {
          showMaxLabel: true,
        },
        boundaryGap: false,
      },
      yAxis: [
        {
          type: 'value',
          name: '交易金额',
          axisLine: {
            show: false, //隐藏坐标轴
          },
          axisTick: {
            show: false, //隐藏刻度线
          },
          axisLabel: {
            show: false, //隐藏值
          },
          position: 'left',
          splitLine: {
            //网格线
            lineStyle: {
              type: 'dashed', //设置网格线类型 dotted：虚线   solid:实线
            },
          },
        },
        {
          type: 'value',
          name: '订单数',
          axisLine: {
            show: false, //隐藏坐标轴
          },
          axisTick: {
            show: false, //隐藏刻度线
          },
          axisLabel: {
            show: false, //隐藏值
          },
          position: 'right',
          splitLine: {
            //网格线
            lineStyle: {
              type: 'dashed', //设置网格线类型 dotted：虚线   solid:实线
            },
          },
        },
      ],
      legend: {
        orient: 'horizontal',
        padding: [0, 0, 10, 0],
        data: ['交易金额', '订单数'],
      },
      series: [
        {
          name: '交易金额',
          data: this.yUser,
          type: 'line',
          smooth: true,
          symbolSize: 6,
          yAxisIndex: 0,
          itemStyle: {
            color: '#6DD181',
          },
        },
        {
          name: '订单数',
          data: this.yCount,
          type: 'line',
          smooth: true,
          symbolSize: 6,
          yAxisIndex: 1,
          itemStyle: {
            color: '#657AFF',
          },
        },
      ],
    });
  }
  //店铺销售额排名
  private async checkSalesRanking() {
    const list = await orders.find(stage =>
      stage
        .$match(match =>
          match.$and(and => {
            and(query => {
              query(
                f => f('spec')('applicationId'),
                e => e.$eq(this.applicationId),
              )(
                f => f('status')('payInfo'),
                e => e.$exists(true),
              )(
                f => f('spec')('type'),
                e => e.$eq('商城'),
              );
              return query;
            });
            if (this.searchData.time && this.searchData.time.length > 0) {
              and(query =>
                query(
                  f => f('metadata')('creationTimestamp'),
                  e => e.$gte(moment(this.searchData.time[0]).toDate()),
                ),
              );
              and(query =>
                query(
                  f => f('metadata')('creationTimestamp'),
                  e =>
                    e.$lte(
                      moment(this.searchData.time[1])
                        .add({day: 1})
                        .subtract({millisecond: 1})
                        .toDate(),
                    ),
                ),
              );
            }
            return and;
          }),
        )
        .$facet(facet =>
          facet('summaryData', summaryStage =>
            summaryStage
              .$group(
                e =>
                  e.$object(object =>
                    object('shopId', e =>
                      e.$fieldPath(f => f('spec')('shopId')),
                    ),
                  ),
                group =>
                  group('user', e =>
                    e.$sum(sum =>
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      sum.$fieldPath(f => f('spec')('amount')('user')),
                    ),
                  )('shopRefundAmount', e =>
                    e.$sum(sum =>
                      sum.$cond(
                        _if =>
                          _if.$eq(
                            e => e.$fieldPath(f => f('spec')('type')),
                            e => e.$literal('商城'),
                          ),
                        _then =>
                          _then.$fieldPath(f => f('spec')('refund')('user')),
                        _else => _else.$literal(0),
                      ),
                    ),
                  )('otherRefundAmount', e =>
                    e.$sum(sum =>
                      sum.$cond(
                        _if =>
                          _if.$or(
                            e =>
                              e.$and(
                                e =>
                                  e.$eq(
                                    e =>
                                      e.$fieldPath(f => f('status')('phase')),
                                    e => e.$literal('退款'),
                                  ),
                                e =>
                                  e.$ne(
                                    e => e.$fieldPath(f => f('spec')('type')),
                                    e => e.$literal('商城'),
                                  ),
                              ),
                            e =>
                              e.$and(
                                e =>
                                  e.$eq(
                                    e =>
                                      e.$fieldPath(f => f('status')('phase')),
                                    e => e.$literal('取消'),
                                  ),
                                e => e.$fieldPath(f => f('status')('payInfo')),
                                e =>
                                  e.$ne(
                                    e => e.$fieldPath(f => f('spec')('type')),
                                    e => e.$literal('商城'),
                                  ),
                              ),
                          ),
                        _then =>
                          _then.$fieldPath(f => f('spec')('amount')('user')),
                        _else => _else.$literal(0),
                      ),
                    ),
                  ),
              )
              .$group(
                e =>
                  e.$object(object =>
                    object('shopId', e =>
                      e.$fieldPath(f => f('_id')('shopId')),
                    ),
                  ),
                group =>
                  group('amount', e =>
                    e.$sum(sum =>
                      sum.$subtract(
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        subtract => subtract.$fieldPath(f => f('user')),
                        subtract =>
                          subtract.$add(
                            e => e.$fieldPath(f => f('shopRefundAmount')),
                            e => e.$fieldPath(f => f('otherRefundAmount')),
                          ),
                      ),
                    ),
                  ),
              )
              .$sort(sort => sort(f => f('amount'), '降序')),
          ),
        ),
    );
    const summaryData = list[0].summaryData.filter((f, i) => i <= 4);
    let shopIdData: Array<ObjectId> = [];
    summaryData.forEach(item => {
      shopIdData.push(item._id.shopId);
    });
    shopIdData = Array.from(new Set(shopIdData));
    const shopData = await shops.find(stage =>
      stage.$match(match =>
        match(
          f => f('_id'),
          e => e.$in(shopIdData),
        )(
          f => f('spec')('applicationId'),
          e => e.$eq(this.applicationId),
        ),
      ),
    );
    this.shopInfoData = lodash.zipObject(
      shopData.map(v => v._id.toHexString()),
      shopData,
    );
    this.shopSalesRanking = summaryData;
  }
  //查询今日交易金额 交易订单数 新增用户数
  private async checkSummary() {
    const list = await orders.find(stage =>
      stage
        .$match(match =>
          match(
            f => f('spec')('applicationId'),
            e => e.$eq(this.applicationId),
          ),
        )
        .$sort(sort => sort(f => f('metadata')('creationTimestamp'), '升序'))
        .$facet(facet =>
          facet('summaryData', summaryStage =>
            summaryStage
              .$match(match =>
                match(
                  f => f('status')('payInfo'),
                  e => e.$exists(true),
                ),
              )
              .$group(
                e => e.$literal(null),
                group =>
                  group('user', e =>
                    e.$sum(sum =>
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      sum.$fieldPath(f => f('spec')('amount')('user')),
                    ),
                  )('count', e => e.$sum(sum => sum.$literal(1))),
              ),
          )('todaySummaryData', todaySummaryData =>
            todaySummaryData
              .$match(match =>
                match.$and(and => {
                  and(query =>
                    query(
                      f => f('metadata')('creationTimestamp'),
                      e => e.$gte(moment().startOf('day').toDate()),
                    ),
                  );
                  and(query =>
                    query(
                      f => f('metadata')('creationTimestamp'),
                      e => e.$lte(moment().endOf('day').toDate()),
                    ),
                  );
                  and(query =>
                    query(
                      f => f('status')('payInfo'),
                      e => e.$exists(true),
                    ),
                  );
                  return and;
                }),
              )
              .$group(
                e => e.$literal(null),
                group =>
                  group('user', e =>
                    e.$sum(sum =>
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      sum.$fieldPath(f => f('spec')('amount')('user')),
                    ),
                  )('count', e => e.$sum(sum => sum.$literal(1))),
              ),
          )('lastSummaryData', lastSummaryData =>
            lastSummaryData
              .$match(match =>
                match.$and(and => {
                  and(query =>
                    query(
                      f => f('metadata')('creationTimestamp'),
                      e =>
                        e.$gte(
                          moment().subtract({day: 1}).startOf('day').toDate(),
                        ),
                    ),
                  );
                  and(query =>
                    query(
                      f => f('metadata')('creationTimestamp'),
                      e =>
                        e.$lte(
                          moment().subtract({day: 1}).endOf('day').toDate(),
                        ),
                    ),
                  );
                  and(query =>
                    query(
                      f => f('status')('payInfo'),
                      e => e.$exists(true),
                    ),
                  );
                  return and;
                }),
              )
              .$group(
                e => e.$literal(null),
                group =>
                  group('user', e =>
                    e.$sum(sum =>
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      sum.$fieldPath(f => f('spec')('amount')('user')),
                    ),
                  )('count', e => e.$sum(sum => sum.$literal(1))),
              ),
          )('pendingPaymentNum', pendingPaymentNum =>
            pendingPaymentNum
              .$match(match =>
                match(
                  f => f('status')('phase'),
                  e => e.$eq('确认'),
                ),
              )
              .$count('count'),
          )('deliveredNum', deliveredNum =>
            deliveredNum
              .$match(match =>
                match(
                  f => f('status')('phase'),
                  e => e.$in(['支付', '接单']),
                ),
              )
              .$count('count'),
          ),
        ),
    );

    const user = await users.find(stage =>
      stage
        .$match(match => {
          match(
            f => f('spec')('applicationId'),
            e => e.$eq(this.applicationId),
          );
          return match;
        })
        .$facet(facet =>
          facet('userNumTotalNum', countStage => countStage.$count('count'))(
            'todayNewUsers',
            todayNewUsers =>
              todayNewUsers
                .$match(match =>
                  match.$and(and => {
                    and(query =>
                      query(
                        f => f('metadata')('creationTimestamp'),
                        e => e.$gte(moment().startOf('day').toDate()),
                      ),
                    );
                    and(query =>
                      query(
                        f => f('metadata')('creationTimestamp'),
                        e => e.$lte(moment().endOf('day').toDate()),
                      ),
                    );
                    return and;
                  }),
                )
                .$count('count'),
          )('lastNewUsers', lastNewUsers =>
            lastNewUsers
              .$match(match =>
                match.$and(and => {
                  and(query =>
                    query(
                      f => f('metadata')('creationTimestamp'),
                      e =>
                        e.$gte(
                          moment().subtract({day: 1}).startOf('day').toDate(),
                        ),
                    ),
                  );
                  and(query =>
                    query(
                      f => f('metadata')('creationTimestamp'),
                      e =>
                        e.$lte(
                          moment().subtract({day: 1}).endOf('day').toDate(),
                        ),
                    ),
                  );
                  return and;
                }),
              )
              .$count('count'),
          ),
        ),
    );
    //交易金额
    const transactionAmountTodayNum =
      list[0].todaySummaryData.length > 0
        ? list[0].todaySummaryData[0].user / 100
        : 0;
    const transactionAmountLastNum =
      list[0].lastSummaryData.length > 0
        ? list[0].lastSummaryData[0].user / 100
        : 0;
    //交易订单
    const transactionNumTodayNum =
      list[0].todaySummaryData.length > 0
        ? list[0].todaySummaryData[0].count
        : 0;
    const transactionNumLastNum =
      list[0].lastSummaryData.length > 0 ? list[0].lastSummaryData[0].count : 0;
    //用户人数
    const userNumTodayNum = user[0].todayNewUsers[0]
      ? user[0].todayNewUsers[0].count.valueOf()
      : 0;
    const userNumLastNum = user[0].lastNewUsers[0]
      ? user[0].lastNewUsers[0].count.valueOf()
      : 0;

    this.transactionAmount = {
      todayNum: transactionAmountTodayNum,
      lastNum: transactionAmountLastNum,
      totalNum:
        list[0].summaryData.length > 0 ? list[0].summaryData[0].user / 100 : 0,
      rate: transactionAmountLastNum
        ? (
            Number(
              (transactionAmountTodayNum * 10000 -
                transactionAmountLastNum * 10000) /
                transactionAmountLastNum,
            ) / 100
          ).toFixed(2)
        : 0,
    };
    this.transactionNum = {
      todayNum: transactionNumTodayNum,
      lastNum: transactionNumLastNum,
      totalNum:
        list[0].summaryData.length > 0 ? list[0].summaryData[0].count : 0,
      rate: transactionNumLastNum
        ? (
            Number(
              (transactionNumTodayNum * 10000 - transactionNumLastNum * 10000) /
                transactionNumLastNum,
            ) / 100
          ).toFixed(2)
        : 0,
    };
    this.userNum = {
      todayNum: userNumTodayNum,
      lastNum: userNumLastNum,
      totalNum: user[0].userNumTotalNum[0]
        ? user[0].userNumTotalNum[0].count.valueOf()
        : 0,
      rate: userNumLastNum
        ? (
            Number(
              (userNumTodayNum * 10000 - userNumLastNum * 10000) /
                userNumLastNum,
            ) / 100
          ).toFixed(2)
        : 0,
    };

    //待付款订单 待发货订单
    this.pendingPaymentNum = list[0].pendingPaymentNum[0]
      ? list[0].pendingPaymentNum[0].count.valueOf()
      : 0;
    this.deliveredNum = list[0].deliveredNum[0]
      ? list[0].deliveredNum[0].count.valueOf()
      : 0;
  }
  //查询营业中的店铺数
  private async checkBusinessShop() {
    const list = await shops.find(stage =>
      stage
        .$match(match =>
          match(
            f => f('spec')('applicationId'),
            e => e.$eq(this.applicationId),
          ),
        )
        .$facet(facet =>
          facet('totalNum', countStage => countStage.$count('count'))(
            'todayNum',
            countStage =>
              countStage
                .$match(match =>
                  match.$and(and => {
                    and(query =>
                      query(
                        f => f('spec')('service'),
                        e => e.$eq('开业'),
                      )(
                        f => f('spec')('serviceTime')('times')('begin'),
                        e => e.$lte(moment().format('HH:mm')),
                      )(
                        f => f('spec')('serviceTime')('times')('end'),
                        e => e.$gte(moment().format('HH:mm')),
                      ),
                    );
                    return and;
                  }),
                )
                .$count('count'),
          ),
        ),
    );
    this.businessShop = {
      todayNum: list[0].todayNum[0] ? list[0].todayNum[0].count.valueOf() : 0,
      totalNum: list[0].totalNum[0] ? list[0].totalNum[0].count.valueOf() : 0,
    };
  }
  //待处理退款单 提现待审核 版本待发布 骑手待审核
  private async checkNum() {
    //待处理退款单
    const afterSaleOrder = await afterSaleOrders.find(stage =>
      stage
        .$match(match =>
          match.$and(and => {
            and(query => {
              query(
                f => f('spec')('applicationId'),
                e => e.$eq(this.applicationId),
              )(
                f => f('status')('phase'),
                e => e.$eq('申请'),
              );
              return query;
            });
            return and;
          }),
        )
        .$facet(facet =>
          facet('count', countStage => countStage.$count('count')),
        ),
    );
    this.pendingRefund = afterSaleOrder[0].count[0]
      ? afterSaleOrder[0].count[0].count.valueOf()
      : 0;

    //提现待审核
    const order = await orders.find(stage =>
      stage
        .$match(match =>
          match.$and(and => {
            and(query => {
              query(
                f => f('spec')('applicationId'),
                e => e.$eq(this.applicationId),
              )(
                f => f('spec')('type'),
                e => e.$eq('提现'),
              )(
                f => f('status')('phase'),
                e => e.$eq('下单'),
              );
              return query;
            });
            return and;
          }),
        )
        .$facet(facet =>
          facet('count', countStage => countStage.$count('count')),
        ),
    );
    this.withdrawNum = order[0].count[0]
      ? order[0].count[0].count.valueOf()
      : 0;

    //骑手待审核
    const riderSignupRequest = await riderSignupRequests.find(stage =>
      stage
        .$match(match => {
          match(
            f => f('status'),
            e => e.$exists(false),
          )(
            f => f('spec')('applicationId'),
            e => e.$eq(this.applicationId),
          );
          return match;
        })
        .$facet(facet =>
          facet('count', countStage => countStage.$count('count')),
        ),
    );
    this.riderReviewed = riderSignupRequest[0].count[0]
      ? riderSignupRequest[0].count[0].count.valueOf()
      : 0;

    //版本待发布
    const channel = await channels.find(stage =>
      stage
        .$match(match => {
          match(
            f => f('status')('wechatCode')('phase'),
            e => e.$eq('通过'),
          )(
            f => f('spec')('applicationId'),
            e => e.$eq(this.applicationId),
          );
          return match;
        })
        .$facet(facet =>
          facet('count', countStage => countStage.$count('count')),
        ),
    );
    this.versionReleased = channel[0].count[0]
      ? channel[0].count[0].count.valueOf()
      : 0;
  }
  //选择自定义日期
  private changeTimeFrame() {
    this.time = '';
    this.checkCharts();
    this.checkSalesRanking();
  }
  //选择时间按钮
  private changeTime() {
    if (this.time === '-1') {
      this.searchData.time = [
        moment({hour: 0}).subtract({day: 1}).toDate(),
        moment({hour: 0}).subtract({day: 1}).toDate(),
      ];
    } else {
      this.searchData.time = [
        moment({hour: 0})
          .subtract({day: Number(this.time)})
          .toDate(),
        moment({hour: 0}).toDate(),
      ];
    }
    this.checkCharts();
    this.checkSalesRanking();
  }
  //跳转详情
  private to_detail(num: number, type: string) {
    if (num > 0) {
      if (type === '待付款订单') {
        this.$router.push({
          path:
            '/application/' + this.$route.params.applicationId + '/order/index',
          query: {
            menu: '订单列表',
            type: 'list',
            orderStatus: '确认',
          },
        });
      } else if (type === '待发货订单') {
        this.$router.push({
          path:
            '/application/' + this.$route.params.applicationId + '/order/index',
          query: {
            menu: '订单列表',
            type: 'list',
            orderStatus: '待发货',
          },
        });
      } else if (type === '待处理退款单') {
        this.$router.push({
          path:
            '/application/' + this.$route.params.applicationId + '/order/index',
          query: {
            menu: '售后订单',
            type: 'list',
            orderStatus: '申请',
          },
        });
      } else if (type === '提现待审核') {
        this.$router.push({
          path:
            '/application/' + this.$route.params.applicationId + '/order/index',
          query: {
            menu: '提现申请单',
            type: 'list',
            orderStatus: '下单',
          },
        });
      } else if (type === '版本待发布') {
        this.$router.push({
          path:
            '/application/' +
            this.$route.params.applicationId +
            '/channel/index',
        });
      } else if (type === '骑手待审核') {
        this.$router.push({
          path:
            '/application/' +
            this.$route.params.applicationId +
            '/delivery/index',
          query: {
            menu: '申请列表',
          },
        });
      }
    }
  }
}
