
import {Component, Vue} from 'vue-property-decorator';
import moment from 'moment';
import {
  riderSignupRequests,
  channels,
  applications,
  deliveryOrders,
  riderInfos,
} from '../../../resources';
import {ObjectId} from 'bson';
import * as echarts from 'echarts';
import {DeliveryUserInfo1} from '@/externals/MaxCI-DeliveryOrder-v1';

interface SummaryData {
  todayNum: number;
  lastNum?: number;
  rate?: number | string;
  totalNum: number;
  baseFee?: number;
  toHomeFee?: number;
}
@Component({
  name: 'deliveryOverviewIndex',
})
export default class extends Vue {
  private time = '6';
  private updateTime = ''; //更新时间
  private deliveryOrderCount: null | SummaryData = null; //配送送达单数
  private deliveryFee: null | SummaryData = null; //配送费收入
  private riderFee: null | SummaryData = null; //骑手费用
  private riderTotalNum = 0; //配送员总数
  private workingRiderNum = 0; //上班中的配送员数量
  private riderReviewed = 0; //骑手待审核
  private versionReleased = 0; //版本待发布
  private pendingOrderNum = 0; //待接单订单
  private pendingPickupNum = 0; //待取货订单
  private toBeServedNum = 0; //待送达订单
  private toBeConfigured = 0; //待配置配送费
  private riderRanking: Array<{
    _id: {riderId?: ObjectId};
    count: number;
    riderInfo?: DeliveryUserInfo1;
  }> = []; //店铺排行榜
  private searchData = {
    time: [
      moment({hour: 0})
        .subtract({day: Number(6)})
        .toDate(),
      moment({hour: 0}).toDate(),
    ],
  };
  private applicationId = ObjectId.createFromHexString(
    this.$route.params.applicationId,
  );
  private xData: Array<string> = [];
  private yUser: Array<number> = [];
  private yCount: Array<number> = [];
  async created() {
    this.updateTime = moment().format('YYYY-MM-DD HH:mm:ss');
    this.checkSummary(); //配送送达单数，配送费收入，骑手费用，上班中的配送员数量，配送员总数，待接单，待取货，待送达
    this.checkNum(); //版本待发布 骑手待审核
    this.checkCharts(); //查询图表
    this.riderDeliveryRanking(); //骑手配送单数排名
    this.$store.state.applicationId = this.$route.params.applicationId;

    const application = (
      await applications.find(stage =>
        stage.$match(match => {
          match(
            f => f('_id'),
            e => e.$eq(this.applicationId),
          );
          return match;
        }),
      )
    ).find(() => true);
    if (!application?.spec.serviceConfigs?.配送) {
      this.toBeConfigured = 2;
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private shopInfoData: any = {};
  mounted() {
    const echartbox = document.getElementById('echartbox') as HTMLCanvasElement;
    window.onresize = () => {
      return (() => {
        echarts.init(echartbox).resize();
      })();
    };
  }
  //配送送达单数，配送费收入，骑手费用，上班中的配送员数量，配送员总数，待接单，待取货，待送达
  private async checkSummary() {
    const deliveryOrder = await deliveryOrders.find(stage =>
      stage
        .$match(match =>
          match(
            f => f('spec')('applicationId'),
            e => e.$eq(this.applicationId),
          ),
        )
        .$sort(sort => sort(f => f('metadata')('creationTimestamp'), '升序'))
        .$facet(facet =>
          facet('summaryData', summaryStage =>
            summaryStage
              .$match(match =>
                match(
                  f => f('status')('phase'),
                  e => e.$eq('完成'),
                ),
              )
              .$group(
                e => e.$literal(null),
                group =>
                  group('shopFeeBaseFee', e =>
                    e.$sum(sum =>
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      sum.$fieldPath(f => f('spec')('shopFee')('baseFee')),
                    ),
                  )('shopFeeToHomeFee', e =>
                    e.$sum(sum =>
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      sum.$fieldPath(f => f('spec')('shopFee')('toHomeFee')),
                    ),
                  )('count', e => e.$sum(sum => sum.$literal(1)))(
                    'riderFeeBaseFee',
                    e =>
                      e.$sum(sum =>
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        sum.$fieldPath(f => f('spec')('riderFee')('baseFee')),
                      ),
                  )('riderFeeToHomeFee', e =>
                    e.$sum(sum =>
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      sum.$fieldPath(f => f('spec')('riderFee')('toHomeFee')),
                    ),
                  ),
              ),
          )('todaySummaryData', todaySummaryData =>
            todaySummaryData
              .$match(match =>
                match.$and(and => {
                  and(query =>
                    query(
                      f => f('status')('phase'),
                      e => e.$eq('完成'),
                    ),
                  );
                  and(query =>
                    query(
                      f => f('metadata')('creationTimestamp'),
                      e =>
                        e.$gte(moment().utcOffset(8).startOf('day').toDate()),
                    ),
                  );
                  and(query =>
                    query(
                      f => f('metadata')('creationTimestamp'),
                      e => e.$lte(moment().utcOffset(8).endOf('day').toDate()),
                    ),
                  );
                  return and;
                }),
              )
              .$group(
                e => e.$literal(null),
                group =>
                  group('shopFeeBaseFee', e =>
                    e.$sum(sum =>
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      sum.$fieldPath(f => f('spec')('shopFee')('baseFee')),
                    ),
                  )('shopFeeToHomeFee', e =>
                    e.$sum(sum =>
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      sum.$fieldPath(f => f('spec')('shopFee')('toHomeFee')),
                    ),
                  )('count', e => e.$sum(sum => sum.$literal(1)))(
                    'riderFeeBaseFee',
                    e =>
                      e.$sum(sum =>
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        sum.$fieldPath(f => f('spec')('riderFee')('baseFee')),
                      ),
                  )('riderFeeToHomeFee', e =>
                    e.$sum(sum =>
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      sum.$fieldPath(f => f('spec')('riderFee')('toHomeFee')),
                    ),
                  ),
              ),
          )('lastSummaryData', lastSummaryData =>
            lastSummaryData
              .$match(match =>
                match.$and(and => {
                  and(query =>
                    query(
                      f => f('status')('phase'),
                      e => e.$eq('完成'),
                    ),
                  );
                  and(query =>
                    query(
                      f => f('metadata')('creationTimestamp'),
                      e =>
                        e.$gte(
                          moment()
                            .utcOffset(8)
                            .subtract({day: 1})
                            .startOf('day')
                            .toDate(),
                        ),
                    ),
                  );
                  and(query =>
                    query(
                      f => f('metadata')('creationTimestamp'),
                      e =>
                        e.$lte(
                          moment()
                            .utcOffset(8)
                            .subtract({day: 1})
                            .endOf('day')
                            .toDate(),
                        ),
                    ),
                  );
                  return and;
                }),
              )
              .$group(
                e => e.$literal(null),
                group =>
                  group('shopFeeBaseFee', e =>
                    e.$sum(sum =>
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      sum.$fieldPath(f => f('spec')('shopFee')('baseFee')),
                    ),
                  )('shopFeeToHomeFee', e =>
                    e.$sum(sum =>
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      sum.$fieldPath(f => f('spec')('shopFee')('toHomeFee')),
                    ),
                  )('count', e => e.$sum(sum => sum.$literal(1)))(
                    'riderFeeBaseFee',
                    e =>
                      e.$sum(sum =>
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        sum.$fieldPath(f => f('spec')('riderFee')('baseFee')),
                      ),
                  )('riderFeeToHomeFee', e =>
                    e.$sum(sum =>
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      sum.$fieldPath(f => f('spec')('riderFee')('toHomeFee')),
                    ),
                  ),
              ),
          )('pendingOrderNum', pendingOrderNum =>
            pendingOrderNum
              .$match(match =>
                match(
                  f => f('status')('phase'),
                  e => e.$eq('下单'),
                ),
              )
              .$group(
                e => e.$literal(null),
                group => group('count', e => e.$sum(sum => sum.$literal(1))),
              ),
          )('pendingPickupNum', pendingPickupNum =>
            pendingPickupNum
              .$match(match =>
                match(
                  f => f('status')('phase'),
                  e => e.$in(['接单', '分拣', '到店']),
                ),
              )
              .$group(
                e => e.$literal(null),
                group => group('count', e => e.$sum(sum => sum.$literal(1))),
              ),
          )('toBeServedNum', toBeServedNum =>
            toBeServedNum
              .$match(match =>
                match(
                  f => f('status')('phase'),
                  e => e.$in(['取货']),
                ),
              )
              .$group(
                e => e.$literal(null),
                group => group('count', e => e.$sum(sum => sum.$literal(1))),
              ),
          ),
        ),
    );
    const riderInfo = await riderInfos.find(stage =>
      stage
        .$match(match => {
          match(
            f => f('spec')('applicationId'),
            e => e.$eq(this.applicationId),
          );
          return match;
        })
        .$facet(facet =>
          facet('riderTotalNum', countStage => countStage.$count('count'))(
            'workingRiderNum',
            todayNewUsers =>
              todayNewUsers
                .$match(match =>
                  match(
                    f => f('spec')('working'),
                    e => e.$eq(true),
                  ),
                )
                .$count('count'),
          ),
        ),
    );

    //上班中的配送员数量，配送员总数
    if (riderInfo.length > 0) {
      this.riderTotalNum =
        riderInfo[0].riderTotalNum.length > 0
          ? riderInfo[0].riderTotalNum[0].count
          : 0;
      this.workingRiderNum =
        riderInfo[0].workingRiderNum.length > 0
          ? riderInfo[0].workingRiderNum[0].count
          : 0;
    }

    if (deliveryOrder.length > 0) {
      //待接单，待取货，待送达
      if (deliveryOrder[0].pendingOrderNum.length > 0) {
        this.pendingOrderNum = deliveryOrder[0].pendingOrderNum[0].count;
      }
      if (deliveryOrder[0].pendingPickupNum.length > 0) {
        this.pendingPickupNum = deliveryOrder[0].pendingPickupNum[0].count;
      }
      if (deliveryOrder[0].toBeServedNum.length > 0) {
        this.toBeServedNum = deliveryOrder[0].toBeServedNum[0].count;
      }
      //配送送达单数
      const deliveryOrderCountTodayNum =
        deliveryOrder[0].todaySummaryData.length > 0
          ? deliveryOrder[0].todaySummaryData[0].count
          : 0;
      const deliveryOrderCountLastNum =
        deliveryOrder[0].lastSummaryData.length > 0
          ? deliveryOrder[0].lastSummaryData[0].count
          : 0;
      this.deliveryOrderCount = {
        todayNum: deliveryOrderCountTodayNum,
        lastNum: deliveryOrderCountLastNum,
        totalNum:
          deliveryOrder[0].summaryData.length > 0
            ? deliveryOrder[0].summaryData[0].count
            : 0,
        rate: deliveryOrderCountLastNum
          ? (
              Number(
                (deliveryOrderCountTodayNum * 10000 -
                  deliveryOrderCountLastNum * 10000) /
                  deliveryOrderCountLastNum,
              ) / 100
            ).toFixed(2)
          : 0,
      };

      //配送费收入
      const deliveryFeeTodayNum =
        deliveryOrder[0].todaySummaryData.length > 0
          ? (deliveryOrder[0].todaySummaryData[0].shopFeeBaseFee +
              deliveryOrder[0].todaySummaryData[0].shopFeeToHomeFee) /
            100
          : 0;
      const deliveryFeeLastNum =
        deliveryOrder[0].lastSummaryData.length > 0
          ? (deliveryOrder[0].lastSummaryData[0].shopFeeBaseFee +
              deliveryOrder[0].lastSummaryData[0].shopFeeToHomeFee) /
            100
          : 0;
      this.deliveryFee = {
        baseFee:
          deliveryOrder[0].todaySummaryData.length > 0
            ? deliveryOrder[0].todaySummaryData[0].shopFeeBaseFee / 100
            : 0,
        toHomeFee:
          deliveryOrder[0].todaySummaryData.length > 0
            ? deliveryOrder[0].todaySummaryData[0].shopFeeToHomeFee / 100
            : 0,
        todayNum: deliveryFeeTodayNum,
        lastNum: deliveryFeeLastNum,
        totalNum:
          deliveryOrder[0].summaryData.length > 0
            ? (deliveryOrder[0].summaryData[0].shopFeeBaseFee +
                deliveryOrder[0].summaryData[0].shopFeeToHomeFee) /
              100
            : 0,
        rate: deliveryFeeLastNum
          ? (
              Number(
                (deliveryFeeTodayNum * 10000 - deliveryFeeLastNum * 10000) /
                  deliveryFeeLastNum,
              ) / 100
            ).toFixed(2)
          : 0,
      };

      //骑手费用
      const riderFeeTodayNum =
        deliveryOrder[0].todaySummaryData.length > 0
          ? (deliveryOrder[0].todaySummaryData[0].riderFeeBaseFee +
              deliveryOrder[0].todaySummaryData[0].riderFeeToHomeFee) /
            100
          : 0;
      const riderFeeLastNum =
        deliveryOrder[0].lastSummaryData.length > 0
          ? (deliveryOrder[0].lastSummaryData[0].riderFeeBaseFee +
              deliveryOrder[0].lastSummaryData[0].riderFeeToHomeFee) /
            100
          : 0;
      this.riderFee = {
        baseFee:
          deliveryOrder[0].todaySummaryData.length > 0
            ? deliveryOrder[0].todaySummaryData[0].riderFeeBaseFee / 100
            : 0,
        toHomeFee:
          deliveryOrder[0].todaySummaryData.length > 0
            ? deliveryOrder[0].todaySummaryData[0].riderFeeToHomeFee / 100
            : 0,
        todayNum: riderFeeTodayNum,
        lastNum: riderFeeLastNum,
        totalNum:
          deliveryOrder[0].summaryData.length > 0
            ? (deliveryOrder[0].summaryData[0].riderFeeBaseFee +
                deliveryOrder[0].summaryData[0].riderFeeToHomeFee) /
              100
            : 0,
        rate: riderFeeLastNum
          ? (
              Number(
                (riderFeeTodayNum * 10000 - riderFeeLastNum * 10000) /
                  riderFeeLastNum,
              ) / 100
            ).toFixed(2)
          : 0,
      };
    }
  }
  //查询图表
  private async checkCharts() {
    const list = await deliveryOrders.find(stage =>
      stage
        .$match(match =>
          match.$and(and => {
            and(query => {
              query(
                f => f('spec')('applicationId'),
                e => e.$eq(this.applicationId),
              )(
                f => f('status')('phase'),
                e => e.$eq('完成'),
              );
              return query;
            });
            if (this.searchData.time && this.searchData.time.length > 0) {
              and(query =>
                query(
                  f => f('metadata')('creationTimestamp'),
                  e =>
                    e.$gte(
                      moment(this.searchData.time[0])
                        .utcOffset(8)
                        .startOf('day')
                        .toDate(),
                    ),
                ),
              );
              and(query =>
                query(
                  f => f('metadata')('creationTimestamp'),
                  e =>
                    e.$lte(
                      moment(this.searchData.time[1])
                        .utcOffset(8)
                        .endOf('day')
                        .toDate(),
                    ),
                ),
              );
            }
            return and;
          }),
        )
        .$facet(facet =>
          facet('chartsData', chartsStage =>
            chartsStage
              .$group(
                e =>
                  e.$object(object =>
                    object('date', e =>
                      e.$dateToString(
                        date =>
                          date.$fieldPath(f =>
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            f('metadata')('creationTimestamp'),
                          ),
                        format => format.$literal('%Y-%m-%d'),
                        timezone => timezone.$literal('+08'),
                      ),
                    ),
                  ),
                group =>
                  group('riderFee', e =>
                    e.$sum(sum =>
                      sum.$add(
                        e =>
                          e.$fieldPath(f => f('spec')('riderFee')('baseFee')),
                        e =>
                          e.$fieldPath(f => f('spec')('riderFee')('toHomeFee')),
                      ),
                    ),
                  )('count', e => e.$sum(sum => sum.$literal(1))),
              )
              .$sort(sort => sort(f => f('_id')('date'), '升序')),
          ),
        ),
    );
    this.xData = list[0].chartsData.map(v => v._id.date as string);
    this.yUser = list[0].chartsData.map(v => v.riderFee / 100);
    this.yCount = list[0].chartsData.map(v => v.count);
    const chart = document.getElementById('echartbox') as HTMLCanvasElement;
    const myChart = echarts.init(chart);
    // 绘制图表
    myChart.setOption({
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'line',
        },
      },
      grid: {
        left: '5%',
        right: '5%',
        bottom: '3%',
        containLabel: true,
      },
      xAxis: {
        data: this.xData,
        max: this.xData[this.xData.length - 1],
        axisLabel: {
          showMaxLabel: true,
        },
        boundaryGap: false,
      },
      yAxis: [
        {
          type: 'value',
          name: '骑手费用',
          axisLine: {
            show: false, //隐藏坐标轴
          },
          axisTick: {
            show: false, //隐藏刻度线
          },
          axisLabel: {
            show: false, //隐藏值
          },
          position: 'left',
          splitLine: {
            //网格线
            lineStyle: {
              type: 'dashed', //设置网格线类型 dotted：虚线   solid:实线
            },
          },
        },
        {
          type: 'value',
          name: '订单数',
          axisLine: {
            show: false, //隐藏坐标轴
          },
          axisTick: {
            show: false, //隐藏刻度线
          },
          axisLabel: {
            show: false, //隐藏值
          },
          position: 'right',
          splitLine: {
            //网格线
            lineStyle: {
              type: 'dashed', //设置网格线类型 dotted：虚线   solid:实线
            },
          },
        },
      ],
      legend: {
        orient: 'horizontal',
        padding: [0, 0, 10, 0],
        data: ['骑手费用', '订单数'],
      },
      series: [
        {
          name: '骑手费用',
          data: this.yUser,
          type: 'line',
          smooth: true,
          symbolSize: 6,
          yAxisIndex: 0,
          itemStyle: {
            color: '#6DD181',
          },
        },
        {
          name: '订单数',
          data: this.yCount,
          type: 'line',
          smooth: true,
          symbolSize: 6,
          yAxisIndex: 1,
          itemStyle: {
            color: '#657AFF',
          },
        },
      ],
    });
  }
  //店铺销售额排名
  private async riderDeliveryRanking() {
    const list = await deliveryOrders.find(stage =>
      stage
        .$match(match =>
          match.$and(and => {
            and(query => {
              query(
                f => f('spec')('applicationId'),
                e => e.$eq(this.applicationId),
              )(
                f => f('status')('phase'),
                e => e.$eq('完成'),
              );
              return query;
            });
            if (this.searchData.time && this.searchData.time.length > 0) {
              and(query =>
                query(
                  f => f('metadata')('creationTimestamp'),
                  e =>
                    e.$gte(
                      moment(this.searchData.time[0])
                        .utcOffset(8)
                        .startOf('day')
                        .toDate(),
                    ),
                ),
              );
              and(query =>
                query(
                  f => f('metadata')('creationTimestamp'),
                  e =>
                    e.$lte(
                      moment(this.searchData.time[1])
                        .utcOffset(8)
                        .endOf('day')
                        .toDate(),
                    ),
                ),
              );
            }
            return and;
          }),
        )
        .$facet(facet =>
          facet('summaryData', summaryStage =>
            summaryStage
              .$group(
                e =>
                  e.$object(object =>
                    object('riderId', e =>
                      e.$fieldPath(f => f('spec')('riderId')),
                    ),
                  ),
                group =>
                  group('count', e => e.$sum(sum => sum.$literal(1)))(
                    'riderInfo',
                    e =>
                      e.$first(first =>
                        first.$fieldPath(f => f('spec')('riderInfo')),
                      ),
                  ),
              )
              .$sort(sort => sort(f => f('count'), '降序'))
              .$limit(5),
          ),
        ),
    );
    this.riderRanking = list[0].summaryData;
  }
  //版本待发布 骑手待审核
  private async checkNum() {
    //骑手待审核
    const riderSignupRequest = await riderSignupRequests.find(stage =>
      stage
        .$match(match => {
          match(
            f => f('status'),
            e => e.$exists(false),
          )(
            f => f('spec')('applicationId'),
            e => e.$eq(this.applicationId),
          );
          return match;
        })
        .$facet(facet =>
          facet('count', countStage => countStage.$count('count')),
        ),
    );
    this.riderReviewed = riderSignupRequest[0].count[0]
      ? riderSignupRequest[0].count[0].count.valueOf()
      : 0;

    //版本待发布
    const channel = await channels.find(stage =>
      stage
        .$match(match => {
          match(
            f => f('status')('wechatCode')('phase'),
            e => e.$eq('通过'),
          )(
            f => f('spec')('applicationId'),
            e => e.$eq(this.applicationId),
          );
          return match;
        })
        .$facet(facet =>
          facet('count', countStage => countStage.$count('count')),
        ),
    );
    this.versionReleased = channel[0].count[0]
      ? channel[0].count[0].count.valueOf()
      : 0;
  }
  //选择自定义日期
  private changeTimeFrame() {
    this.time = '';
    this.checkCharts();
    this.riderDeliveryRanking();
  }
  //选择时间按钮
  private changeTime() {
    if (this.time === '-1') {
      this.searchData.time = [
        moment({hour: 0}).subtract({day: 1}).toDate(),
        moment({hour: 0}).subtract({day: 1}).toDate(),
      ];
    } else {
      this.searchData.time = [
        moment({hour: 0})
          .subtract({day: Number(this.time)})
          .toDate(),
        moment({hour: 0}).toDate(),
      ];
    }
    this.checkCharts();
    this.riderDeliveryRanking();
  }
  //跳转详情
  private to_detail(num: number, type: string) {
    if (num > 0) {
      if (type === '骑手待审核') {
        this.$router.push({
          path:
            '/application/' +
            this.$route.params.applicationId +
            '/delivery/index',
          query: {
            menu: '申请列表',
          },
        });
      } else if (type === '版本待发布') {
        this.$router.push({
          path:
            '/application/' +
            this.$route.params.applicationId +
            '/channel/index',
        });
      } else if (type === '待接单订单') {
        this.$router.push({
          path:
            '/application/' + this.$route.params.applicationId + '/order/index',
          query: {
            menu: '配送单列表',
            type: 'list',
            orderStatus: '下单',
          },
        });
      } else if (type === '待取货订单') {
        this.$router.push({
          path:
            '/application/' + this.$route.params.applicationId + '/order/index',
          query: {
            menu: '配送单列表',
            type: 'list',
            orderStatus: '待取货',
          },
        });
      } else if (type === '待送达订单') {
        this.$router.push({
          path:
            '/application/' + this.$route.params.applicationId + '/order/index',
          query: {
            menu: '配送单列表',
            type: 'list',
            orderStatus: '取货',
          },
        });
      } else if (type === '待配置配送费') {
        this.$router.push({
          path:
            '/application/' +
            this.$route.params.applicationId +
            '/delivery/index',
          query: {
            menu: '配送费设置',
          },
        });
      }
    }
  }
}
