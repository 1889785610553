
import {Component, Vue} from 'vue-property-decorator';
import MallOverviewIndex from './components/mall-overview-index.vue';
import DeliveryOverviewIndex from './components/delivery-overview-index.vue';
import {applications} from '../../resources';
import {ObjectId} from 'bson';

@Component({
  name: 'overview',
  components: {
    MallOverviewIndex,
    DeliveryOverviewIndex,
  },
})
export default class extends Vue {
  private activeName = '';
  private mallShow = false;
  private deliveryShow = false;
  private applicationId = ObjectId.createFromHexString(
    this.$route.params.applicationId,
  );
  async created() {
    const application = (
      await applications.find(stage =>
        stage.$match(match => {
          match(
            f => f('_id'),
            e => e.$eq(this.applicationId),
          );
          return match;
        }),
      )
    ).find(() => true);
    if (application?.spec.enableServices) {
      application?.spec.enableServices.forEach(item => {
        if (item === '商城') {
          this.mallShow = true;
        }
        if (item === '配送') {
          this.deliveryShow = true;
        }
      });
    }

    if (this.mallShow && this.deliveryShow) {
      this.activeName = '商城概况';
    } else if (this.mallShow && !this.deliveryShow) {
      this.activeName = '商城概况';
    } else if (!this.mallShow && this.deliveryShow) {
      this.activeName = '配送概况';
    }
  }
}
